<template>
    <div class="componentClass">
        <div class="table">
            <el-table
                    id="ETable"
                    ref="ETable"
                    :header-row-style="{background:'rgba(242, 246, 252, 1)',color:'rgba(0, 0, 0, 1)'}"
                    :header-cell-style="{background:'rgba(242, 246, 252, 1)','mso-number-format':'\@'}"
                    height="100%"
                    :data="tableData&&tableData.data&&tableData.data.list?tableData.data.list:
                    (tableData&&tableData.data&&tableData.data.data?tableData.data.data:
                    tableData.data&&tableData.data.payrollList?tableData.data.payrollList:
                    tableData.data&&tableData.data.payrollStaffList?tableData.data.payrollStaffList:
                    (tableData&&tableData.data?tableData.data:[]))"
                    style="width: 100%"
                    :header-cell-class-name="cellClass"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        v-if="tableData&&tableData.selection==1"
                        type="selection"
                        :selectable="selectHandle||defaultHandle"
                        width="55">
                </el-table-column>
                <el-table-column
                        v-if="!(tableData&&tableData.serial==0)"
                        prop="date"
                        width="80"
                        label="序号">
                    <template slot-scope="scope">
                        {{scope.$index+1+(size*(no-1))}}
                    </template>
                </el-table-column>
                <template v-for="(item,i) in tableData?tableData.cols:[]">
                    <el-table-column
                            :key="i"
                            :prop="item&&item.prop?item.prop:''"
                            :label="item.label"
                            v-if="!(tableData.switchCol&&tableData.switchCol[`${item.prop}`]==0)"
                    >
                        <template slot-scope="scope">
                            <slot  :name="item&&item.prop?item.prop:''"  :rowData="scope.row">
                                 {{(item&&item.prop&&item.prop.includes('.')?scope.row[item.prop.split('.')[0]][item.prop.split('.')[1]]:item&&item.prop?scope.row[item.prop]:'--')||'--'}}
                            </slot>
                        </template>
                    </el-table-column>
                </template>
                <el-table-column
                        v-if="!(tableData&&tableData.operate==0)"
                        prop="date"
                        :width="operateWidth"
                        label="操作">
                    <template slot-scope="scope">
                        <slot  name="operate" :rowData="scope.row"></slot>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="paging" v-if="tableData.paging!=0">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="no?no:0"
                    background
                    :page-sizes="[20, 30, 40, 50, 100]"
                    :page-size="size"
                    layout="total, prev, pager, next, sizes"
                    :total="tableData&&tableData.data&&tableData.data.total?tableData.data.total:(tableData&&tableData.data&&tableData.data.count?tableData.data.count:0)">
            </el-pagination>
        </div>
    </div>

</template>

<script>
    export default {
        name: "tableCmp",
        props:['tableData','operateWidth','selectHandle','cellClass','selectionChange'],
        data(){
            return{
                multipleSelection:[],
                size:20,
                no:1,
            }
        },
        created() {
        },
        methods:{
            //选择印章签署处理函数
            defaultHandle(row){
                return true
            },
            //重置no页数
            resetNo(){
                this.no=1
            },
            handleSizeChange(val) {
                this.$emit('handleSizeChange',val)
                this.size=val
                this.no=1
                this.$store.commit('pagingState',{size:this.size,no:this.no})
            },
            handleCurrentChange(val) {
                this.$emit('handleCurrentChange',val)
                this.no=val
                this.$store.commit('pagingState',{size:this.size,no:this.no})
            },
            //数据空白自动跳回上一页
            skipPage(){
                return new Promise((resolve) => {
                    const count = this.tableData&&this.tableData.data&&this.tableData.data.total?this.tableData.data.total:(this.tableData&&this.tableData.data&&this.tableData.data.count?this.tableData.data.count:0)
                    if((count%this.size==1)&&this.no!=1){
                        resolve(1)
                        this.$emit('handleCurrentChange',this.no-1)
                    }else{
                        resolve(0)
                    }
                })
            },
            handleSelectionChange(val){
                //实现单选效果
                if(this.tableData.selectionRadio){
                    if (val.length > 1) {
                        //移除上一次选中行数据
                        val.shift();
                        //修改选中图标为未选中状态
                        this.$refs.ETable.clearSelection();
                        //将当前选中行改为选中状态
                        this.$refs.ETable.toggleRowSelection(val[0]);
                        val=[val[0]]
                    }
                }
                this.multipleSelection = val;
            },
        }
    }
</script>

<style scoped>
    ::v-deep  td{
        mso-number-format:'\@';
    }
    .componentClass{
        width: 100%;
        height: 100%;
    }
    ::v-deep .el-table .el-table__cell.gutter{
        background: transparent !important;
    }
    ::v-deep .el-table-column--selection .cell{
        padding: 0 10px;
        mso-number-format:'\@';
    }
    .table{
        width: 100%;
        height: calc(100% - 50px);
    }
    .paging{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: white;
    }
    /* 去掉全选按钮 */
    ::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
        display: none !important;
    }
</style>
